<template>
	<div class="mainbox" style="height: 100vh;display: flex;background-color: #fff;overflow-y: auto;" @click="hiddenother">
		<div class="home" style="width: 100vw;height: fit-content;">
			<div class="welcome_btn" style="padding-left: 1rem;padding-right: 1.2rem;">
				<div class="cont font26 flex-sb">
					<div style="font-size: 0.2rem;">欢迎您来到西宁市政协门户网站！</div>
					<div class="right"></div>
					<a id="wzayd" title="网站信息无障碍工具条" href="javascript:;" class="wzayd" accesskey="g" style="font-size: 0.2rem;">无障碍阅读</a>
				</div>
			</div>
			<div class="city_banner">
				<!-- <img src="@/assets/home/bng2.png" alt=""> -->
				<transition-group name="fade" mode="out-in">
					<img class="slide" v-if="items[0]" :src="currentItem" :key="currentIndex">
				</transition-group>
		
			</div>
			<div class="bx">
				<homeLogo></homeLogo>
				<homeNavBar />
			</div>
			<router-view :key="$route.path" />
			<div class="bg_f5" style="position: relative;z-index: 15;">
				<div class="bx" style="position: relative;z-index: 5;">
					<copyRight ref="myother"/>
				</div>
				<div style="position: absolute;width: 100vw;left: -5vw;top: 0;background-color: #F5f5f5;height: 100%;">
					
				</div>
			</div>
		
			<div class="leftcode" @click="showcode=!showcode">
				{{showcode?'关闭':'更多链接'}}
			</div>
			<div class="codebox" :class="showcode?'showcodebox':''">
				<div class="innbocd">
					<div style="height: 45%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
						<img :src="wechatcode" alt="" />
						<div style="text-align: center;margin-top:0.1rem;">
							扫一扫关注<br>
							西宁市政协微信公众号
						</div>
					</div>
					<div style="height: 45%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
						<img :src="appcode" alt="" />
						<div style="text-align: center;margin-top:0.1rem;">							
							西宁市<br>
							智慧政协APP下载
						</div>
					</div>
				</div>
			</div>	
		</div>
		
		
	</div>
	
</template>

<script>
	import copyRight from "./homeComponents/copyRight.vue"
	import homeLogo from './homeComponents/homeLogo.vue'
	import homeNavBar from './homeComponents/homeNavBar.vue'


	export default {
		components: {
			homeLogo,
			homeNavBar,
			copyRight
		},
		mounted() {
		    document.title = '西宁市政协';
		},
		data() {
			return {
				items: [
					require('@/assets/home/bng1.png'),
					require('@/assets/home/bng2.png'),
					require('@/assets/home/bng3.png'),
					require('@/assets/home/bng4.png')
				],
				currentIndex: 0,
				showcode:false,
				appcode:'',
				wechatcode:''
			}
		},
		computed: {
			currentItem() {
				return this.items[this.currentIndex];
			}
		},
		methods: {
			hiddenother(){
				if(this.$refs.myother.showicons){
					this.$refs.myother.showicons = false
				}				
			},
			startCarousel() {
				setInterval(() => {
					this.currentIndex = (this.currentIndex + 1) % this.items.length;
				}, 4000);
			}
		},
		created() {
			this.$post({ //获取轮播图
				url: '/api/ad/index',
				params: {
					adtype_id: 7,
					p: 1,
					size: 5
				}
			}).then((res) => {
				// this.items = res.list
				this.startCarousel();
			})
			
			this.$post({ //获取二维码
				url: '/api/pc/demo_config',
				params: {
				}
			}).then((res) => {
				this.appcode = this.$URL + res.pc_app_qrcode
				this.wechatcode = this.$URL + res.pc_wechat_qrcode
			})
		},
	}
</script>
<style scoped lang="scss">
	.mainbox{
		overflow-y: auto;
		overflow-x: hidden;
	}
	@media (max-width: 1200px) {
		.home{
			width: 1200px !important;
			padding: 0 50px !important;
			position: relative;
		}	
		.mainbox{
			overflow-y: auto;
			overflow-x: auto;
			
		}
		
	}
	.leftcode{
		position: fixed;
		left: 0;
		top: 50vh;
		background-image: url('../../assets/gwleft.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-color: rgba(0, 0, 0, 0);
		height: 1.2rem;
		width: 0.5rem;
		z-index: 55;
		color: #fff;
		font-size: 0.18rem;
		writing-mode: vertical-rl;
		text-align: center;
		letter-spacing: 0.05rem;
		padding-right: 0.188rem;
		cursor: pointer;
	}
	.codebox{
		left: 0.39rem;
		top: 50vh;
		position: fixed;
		z-index: 56;
		width: 0;
		height: 0;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;		
		transition: all 0.5s; 
		overflow: hidden;
	}
	.showcodebox{
		width: 2.5rem;
		height: 4rem;
		border: 2px solid rgba(15, 121, 255, 0.37);
		padding: 0.1rem;
	}
	.innbocd{
		width: 100%;
		height: 100%;		
		border: 1px solid #D8D9D9;
		padding: 0.2rem;
		font-size: 0.16rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		img{
			width: 1.2rem;
			height: 1.2rem;
			
		}
	}
	.home {
		background-image: url('../../assets/home/home_bg.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		padding: 0 5vw;
	}

	.welcome_btn {
		justify-content: space-between;
		background: rgba(0, 0, 0, 0.0902);
		color: #fff;
		padding: .14rem 0;
		position: absolute;
		z-index: 10;
		width: 100%;
		left: 0;
		top: 0;

		.cont {
			margin: 0 auto;
			width: 100%;


			.right {
				margin-right: -.02rem;
			}
		}
	}

	.city_banner {
		position: absolute;
		width: 7.09rem;
		height: 4.31rem;
		right: .96rem;
		top: 0;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0;
		position: absolute;
	}

	.bg_f5 {
		background: #F5f5f5;
	}
	@media (max-width: 1200px) {
			.bg_f5{
				width:1200px;
			}
		}

	@import 'ui.css';
</style>